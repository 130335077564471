export var SHMPoint = {
  SHMQRNetSucceed: 90000, // 成功的 网络生成二维码
  SHMQRRefreshSucceed: 90001, // 成功的 刷新二维码
  SHMQRLocalSucceed: 90002, // 成功的 本地刷新二维码

  // 生码错误码
  SHMPointStatus_10000: 10000, // 手机蓝牙地址格式错误
  SHMPointStatus_10001: 10001, // 账户token格式错误
  SHMPointStatus_10002: 10002, // 账户认证码格式错误
  SHMPointStatus_10003: 10003, // 发卡方mac格式错误
  SHMPointStatus_10004: 10004, // 站格式错误
  SHMPointStatus_10005: 10005, // 卡类型错误
  SHMPointStatus_10006: 10006, // 分散因子格式错误
  SHMPointStatus_10007: 10007, // 散因子过期
  SHMPointStatus_10008: 10008, // 二维码刷新次数超出限制
  SHMPointStatus_10009: 10009, // 二维码数据格式错误
  SHMPointStatus_10010: 10010, // 网络获取二维码格式错误
  SHMPointStatus_10011: 10011, // 系统时间小于申请时间 eg:手机时间异常，请检查
  SHMPointStatus_10012: 10012, // 网络获取二维码失败 eg:超时或者服务器挂了
  SHMPointStatus_10013: 10013, // 进站失败
  SHMPointStatus_10014: 10014, // 出站失败
  SHMPointStatus_10015: 10015, // 过程Mac 格式错误
  SHMPointStatus_10016: 10016, // processkey 格式错误

  // 日票错误码
  SHMPointStatus_10020: 10020, // 日票 - 车票子类型异常
  SHMPointStatus_10021: 10021, // 日票 - 车票启用时间异常
  SHMPointStatus_10022: 10022, // 日票 - 车站位图1
  SHMPointStatus_10023: 10023, // 日票 - 车站位图2
  SHMPointStatus_10024: 10024, // 日票 - 范围标志
  SHMPointStatus_10025: 10025, // 日票 - 有效使用时间
  SHMPointStatus_10026: 10026, // 日票 - extendMac

  // 扫码错误码
  SHMPointStatus_20000: 20000, // 33 31数据包长度异常
  SHMPointStatus_20001: 20001, // 数据包mac串校验异常
  SHMPointStatus_20002: 20002, // 闸机回写二维码次数错误
  // 无法生码的错误码
  SHMPointStatus_20003: 20003, // 设备不支持BLE
  SHMPointStatus_20004: 20004, // 设备不支持BLE广播
  SHMPointStatus_20005: 20005, // 安卓设备系统低于5.0
  SHMPointStatus_20006: 20006, // 蓝牙打开超时
  SHMPointStatus_20007: 20007, // ble广播失败

  SHMPointStatus_20008: 20008, // 闸机超时未断开
  SHMPointStatus_20009: 20009, // 33数据返回失败
  SHMPointStatus_20010: 20010, // 31数据返回失败

  SHMPointStatus_20011: 20011, // APData保存失败
  SHMPointStatus_20012: 20012, // 钥匙串保存失败
  SHMPointStatus_20013: 20013, // 账户为空
  SHMPointStatus_20014: 20014, // 蓝牙service失败

  SHMPointStatus_20021: 20021, // 进站失败
  SHMPointStatus_20022: 20022, // 出站失败
  SHMPointStatus_20023: 20023, // 蓝牙打开异常
  SHMPointStatus_20024: 20024, // 用户看到的无法生码

  SHMPointStatus_30016: 30016, // 蓝牙订阅
  SHMPointStatus_30013: 30013, // 双向回写认证
  SHMPointStatus_30017: 30017, // 取消订阅
  SHMPointStatus_30018: 30018, // 广播启动成功
  SHMPointStatus_30019: 30019, // 33数据校验失败
  SHMPointStatus_30020: 30020, // 日票不支持磁浮进出站
};

// const CryptoJS = require('../crypto-js/crypto-js');
import {
  Pboc_des_mac,
  strToArray
} from './SHMTripe';
import {
  strToHexCharCode
} from './SHMUtil';
import {
  SHMPoint
} from './SHMEnum';
import {
  qrCodePointFlow
} from './SHMOperator';
import {
  state
} from '@/store/state';
import {
  setLocalData,
  getLocalData
} from '../jssdk';
import {
  Dialog,
  Toast
} from 'vant'
import {
  hex2base64
} from '../utils';
import store from '../../store';
import {
  mockZhaji
} from '@/unified';

// var SHM_QR = '017eecefab8fd051000000000788513370f4e565cb004c5059ffffd200000000000000000000000000000000000000000000000000f49214a700010000000000000000005e2861895e25be89aabe0b40eda3ed83';
let SHM_QR = '';

let SHM_PROCESS_KEY = '';

const DEFAULT_CODE_PERIOD = 120;

const MAX_REFRESH_COUNTS = 60000;

const K_QR_Length = 256;

let qrLoaded = false;

let isIOS = true;

let pointStatus;

const ticketData = '';
let refreshCountsNum = ''


const isDayTicket = state.dayOrAsingle ? state.dayQrcodeInfo : state.queryInfo;

const unionKey = 'qrcode' + (isDayTicket ? 'day' : '');

let data34 = '';

let operatorDelegate; // operator.js 代理

let currentBroadcast = null;

export function delegate(delegate) {
  operatorDelegate = delegate;
}
export function initOSType(_os) {
  isIOS = _os;
}
export async function getInfoMap() {
  // const res = state.dayOrAsingle ? state.dayQrcodeInfo : state.queryInfo
  const res = state.queryInfo;
  console.log('从store中获取到的码数据: ' + JSON.stringify(res));
  const map = new Map();
  if (res && res.mobile) {

    console.log('bluetoothAddress = strToHexCharCode(res.mobile) = ' + strToHexCharCode(res.mobile));

    if (currentBroadcast == null) {
      currentBroadcast = strToHexCharCode(res.mobile);
    } else {
      //currentBroadcast = (parseInt(currentBroadcast, 16) + 10000000000).toString(16).toUpperCase();
      console.log("保留使用原广播信号: " + currentBroadcast);
    }

    map.set('bluetoothAddress', currentBroadcast);
    map.set('userToken', res.accountToken);
    map.set('certCode', res.accountCertCode);
    map.set('cardMac', res.channelMac);
    map.set('location', 'FFFF');
    map.set('cardType', res.cardType);
    map.set('dataMac', res.dataMac);
    map.set('factor', res.factor);
    map.set('processKey', res.processKey);
    map.set('ticketType', res.cardChildType || '00');
    map.set('activeTime', res.activeTime || '00000000');
    map.set('stationBitmap1', res.stationCode1 || '00000000');
    map.set('stationBitmap2', res.stationCode2 || '00000000');
    map.set('rangTag', res.rangeMark || '00');
    map.set('effectiveHour', res.vaildHours || '00000000');
    map.set('extendMac', res.extendMac || '00000000');
  }
  return map;
}
// 获取二维码数据 需要进行校验
export async function getQRCode(flag) {
  // console.log('getQRCode', flag)

  if (!(await loadLocalData(flag))) {
    return false;
  }
  // await setLocalData({
  //   key: 'logInQRData',
  //   value: SHM_QR,
  // });
  return SHM_QR;
}

// 不需要进行二维码校验
export function getFastQR() {
  return SHM_QR;
}

async function loadLocalData(flag) {
  // console.log('loadLocalData', flag)

  qrLoaded = false;
  await getDataFromLocal();
  // console.log('二维码初始值', (SHM_QR))
  // console.log('二维码初始值', (SHM_QR.length))
  // console.log('二维码初始值', (K_QR_Length))
  // console.log('二维码初始值', (!SHM_QR || SHM_QR.length != K_QR_Length))
  if (!SHM_QR || SHM_QR.length != K_QR_Length) {
    // console.log('二维码初始值全部为0')
    SHM_QR = getInitSHMQR();
  }
  // console.log('二维码初始值', SHM_QR)

  qrLoaded = metroQrCodeRefresh(false, false);
  if (!qrLoaded) {
    console.log('二维码码值本地数据加载失败，从网络请求');
    var map = await getInfoMap();
    qrLoaded = installQrCode(map);
  } else {
    const [logInQRData] = await getLocalData({
      key: 'logInQRData',
    });
    console.log('logInQRData: ', logInQRData);

    var map = await getInfoMap();
    if (map && map.get('bluetoothAddress')) {
      qrLoaded = installQrCode(map);
    }
    console.log('二维码码值本地数据校验合法，直接返回: ' + qrLoaded);

  }
  if (flag === true) {
    operatorDelegate.refreshQRCode(SHM_QR, true);

  }else {
    //console.log('loadLocalData 展示二维码');
    //operatorDelegate.refreshQRCode(SHM_QR, false);

  }
  return qrLoaded;
}

export function installQrCode(info) {
  console.log('开始组装码值', info);
  if (info == null || !info.get('bluetoothAddress')) {
    console.log('码数据为空，直接返回');
    return false;
  }

  // 安卓 00 苹果 01
  const osType = isIOS ? '01' : '00';
  let index = 0;
  index = qrCodeItemPush(index, osType);

  const bluetoothAddress = info.get('bluetoothAddress');
  // 手机号
  if (bluetoothAddress.length != 12) {
    console.log('手机蓝牙地址（bluetoothAddress）格式非法 请检查 = ', bluetoothAddress);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10000;
    return false;
  }
  index = qrCodeItemPush(index, bluetoothAddress);

  // 用户Token
  const userToken = info.get('userToken');
  if (userToken.length != 20) {
    console.log('帐户userToken格式有误 请检查 = ', userToken);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10001;
    return false;
  }
  index = qrCodeItemPush(index, userToken);

  // 账户认证码
  const certCode = info.get('certCode');
  if (certCode.length != 8) {
    console.log('账户认证码certCode格式有误 请检查 = ', certCode);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10002;
    return false;
  }
  index = qrCodeItemPush(index, certCode);

  // 发卡方mac

  const cardMac = info.get('cardMac');
  if (cardMac.length != 8) {
    console.log('发卡方cardMac格式有误 请检查 = ', cardMac);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10003;
    return false;
  }
  index = qrCodeItemPush(index, cardMac);

  // 车站位置
  const location = info.get('location');
  if (location.length != 4) {
    console.log('车站位置location格式有误 请检查 = ', location);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10004;
    return false;
  }
  index = qrCodeItemPush(index, location);

  // 卡类型
  const cardType = info.get('cardType');
  if (cardType.length != 2) {
    console.log('卡类型格式cardType有误 请检查 = ', cardType);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10005;
    return false;
  }
  index = qrCodeItemPush(index, cardType);

  // 过程mac
  index = 106;
  const dataMac = info.get('dataMac');
  if (dataMac.length != 8) {
    console.log('dataMac格式有误 请检查 = ', dataMac);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10015;
    return false;
  }
  if (SHM_QR.substr(106, 8) == '00000000') {
    index = qrCodeItemPush(index, dataMac);
  }
  //index = qrCodeItemPush(index, dataMac);
  // index = 114
  // index = qrCodeItemPush(index, '0000');
  console.log('dataMac', dataMac)
  // 磁浮限定
  //index = 118;
  // const cifu = '41FFFF';
  //const cifu = '000000';
  //index = qrCodeItemPush(index, cifu);

  // 车票子类型
  index = 136;
  const ticketType = info.get('ticketType');
  if (!ticketType || ticketType.length != 2) {
    console.log('车票子类型格式有误 ticketType 请检查 = ', ticketType);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10020;
    return false;
  }
  index = qrCodeItemPush(index, ticketType);

  // 车票启用时间
  const activeTime = info.get('activeTime');
  if (activeTime.length != 8) {
    console.log('车票启用时间有误 activeTime 请检查 = ', activeTime);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10021;
    return false;
  }
  index = qrCodeItemPush(index, activeTime);

  // 线路位图/车站位图1
  // index = 36;
  const stationBitmap1 = info.get('stationBitmap1');
  if (stationBitmap1.length != 8) {
    console.log('线路位图/车站位图1 stationBitmap1 请检查 = ', stationBitmap1);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10022;
    return false;
  }
  index = qrCodeItemPush(index, stationBitmap1);

  // 线路位图/车站位图1
  // index = 36;
  const stationBitmap2 = info.get('stationBitmap2');
  if (stationBitmap1.length != 8) {
    console.log('线路位图/车站位图2 stationBitmap2 请检查 = ', stationBitmap2);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10023;
    return false;
  }
  index = qrCodeItemPush(index, stationBitmap2);

  // 范围标志
  const rangTag = info.get('rangTag');
  if (rangTag.length != 2) {
    console.log('范围标志/范围标志 rangTag 请检查 = ', rangTag);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10024;
    return false;
  }
  index = qrCodeItemPush(index, rangTag);

  // 有效使用时间
  const effectiveHour = info.get('effectiveHour');
  // 判断是日票还是单程票,state.dayOrAsingle为false是单程票,日票位数4，单程票位数8
  if (state.dayOrAsingle) {
    if (effectiveHour.length != 4) {
      console.log('有效使用时间effectiveHour生码部分有误 请检查 = ', effectiveHour);
      // _qrCodeStatus = SHMPoint.SHMPointStatus_10025;
      return false;
    }
  } else {
    if (effectiveHour.length != 8) {
      console.log('有效使用时间生码effectiveHour部分有误 请检查 = ', effectiveHour);
      // _qrCodeStatus = SHMPoint.SHMPointStatus_10025;
      return false;
    }
  }

  index = qrCodeItemPush(index, effectiveHour);

  // 预留
  index = 216;
  const extendMac = info.get('extendMac');
  if (extendMac.length != 8) {
    console.log('预留extendMac请检查 = ', extendMac);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10026;
    return false;
  }
  index = qrCodeItemPush(index, extendMac);

  index = 232;
  // 分散因子
  const factor = info.get('factor');
  if (factor.length != 16) {
    console.log('分散因子factor格式有误 请检查 = ', factor);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10006;
    return false;
  }
  index = qrCodeItemPush(index, factor);

  // processKey
  const processKey = info.get('processKey');
  if (processKey.length != 32) {
    console.log('processKey格式有误 请检查 = ', factor);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10006;
    return false;
  }
  SHM_PROCESS_KEY = processKey;
  console.log('二维码码值组装完成 = ' + SHM_QR);
  return metroQrCodeRefresh(false, false);
}

export function changeBroadcast() {

  var shmqr = SHM_QR;
  console.log('重置广播信号之前', shmqr);

  if (shmqr && !shmqr.startsWith("000000000")) {

    var oldCode = shmqr.substr(2, 12);
    currentBroadcast = (parseInt(oldCode, 16) + 10000000000).toString(16).toUpperCase();
    console.log('oldCode: ' + oldCode + ", currentBroadcast: " + currentBroadcast);
    SHM_QR = SHM_QR.substr(0, 2) + currentBroadcast + SHM_QR.substr(14);

    refreshappAuthCode(true)

    console.log('完成重置广播信号', SHM_QR);
  }
}

export function getBroadCast() {
  const shmqr = SHM_QR;
  if (!shmqr || shmqr.length != 256) {
    console.log('获取广播失败');
    return false;
  }
  console.log('getBroadCast broadCast = ', shmqr.substr(2, 12));
  return shmqr.substr(2, 12).toUpperCase();
}

function metroQrCodeRefresh(isCountAdd, isRewrite) {
  // console.log('SHM_QR--' + SHM_QR);
  const current_seconds = getCurrentSeconds();
  // console.log('SHM_QR--' + SHM_QR);
  // 获取二维码请求时间
  const tmpData = SHM_QR.substr(232, 8);

  const request_time = parseInt(tmpData, 16);
  // 计算分散因子的过期时间
  const tmp_time = getFactorTime();
  const period_time = request_time + tmp_time * 60;

  // #ifdef DEBUG
  // //    period_time = request_time + 60;
  //    request_time = current_seconds + 100;
  //
  // #endif

  if (current_seconds + 120 < request_time) {
    console.log('系统时间小于请求时间, current_seconds: ' + current_seconds + ", tmpData: " + tmpData + ", request_time: " + request_time);

    Dialog.confirm({
      title: '提示',
      message: '手机时间异常，请调整后重试',
      showCancelButton: false,
      confirmButtonText: '确认'
    }).then(() => {
      console.log('确认');
      resolve([false]);
    })

    // _qrCodeStatus = SHMPoint.SHMPointStatus_10011;
    return false;
  }

  let tmp_period = period_time - DEFAULT_CODE_PERIOD;

  if (isRewrite) {
    console.log('回写时二维码有效期不减去刷新时间');
    tmp_period = period_time;
  }

  if (current_seconds >= tmp_period) {
    console.log('分散因子过期');

    // _qrCodeStatus = SHMPoint.SHMPointStatus_10007;
    return false;
  }
  // #ifdef DEBUG
  //    isCountAdd  = NO;
  // #endif
  // 获取当前的二维码刷新次数
  let refreshCounts = parseInt(SHM_QR.substr(114, 4), 16);
  if (isCountAdd) {
    refreshCounts++;
  }

  if (refreshCounts >= MAX_REFRESH_COUNTS) {
    console.log('当前二维码刷新次数超出限制 请重新初始化 refreshCounts:', refreshCounts);
    // 生码失败埋点
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10008;
    return false;
  }
  refreshCounts = refreshCounts.toString(16);
  // console.log(
  //   `开始本地刷新二维码时间戳
  //   currentTime=${current_seconds}
  //   requestTime=${request_time}
  //   periodTime=${period_time}
  //   refreshCounts=${refreshCounts}`,
  // );

  if (refreshCounts.length == 1) {
    refreshCounts = `000${refreshCounts}`;
  } else if (refreshCounts.length == 2) {
    refreshCounts = `00${refreshCounts}`;
  } else if (refreshCounts.length == 3) {
    refreshCounts = `0${refreshCounts}`;
  }

  if (refreshCounts.length == 4) {
    qrCodeItemPush(114, refreshCounts);
  } else {
    qrCodeItemPush(114, '0001');
  }

  const index = 224;
  // 计算当前二维码的有效期
  qrCodeItemPush(index, period_time.toString(16));
  // refreshappAuthCode(true);
  // return true;
  return refreshappAuthCode(true);
}

async function refreshappAuthCode(isSaveLocal) {
  SHM_QR = SHM_QR.toLowerCase();
  const authCode = getMacBySrc(SHM_QR.substr(0, K_QR_Length - 8));
  qrCodeItemPush(K_QR_Length - 8, authCode);
  // console.log('authCode = ' + authCode);
  // console.log(`SHMQRCode  = ${SHM_QR}`);
  if (isSaveLocal) {
    await setDataToLocal();
  }
  return SHM_QR;
  // 埋点生码成功状态
  // _qrCodeStatus = SHMPoint.SHMQRRefreshSucceed;
}

function qrCodeItemPush(index, data) {
  // console.log(`index = ${index} data = `, data);
  SHM_QR = changeStr(SHM_QR, index, data);
  return index + data.length;
}
// 校验33数据是否正常
export function qrCodeValidate(appAuthCode) {
  // console.log('返回的33数据  qrCodeValidate');
  const localCode = SHM_QR.substr(K_QR_Length - 8, 8);
  // console.log('localCode :>> ', localCode);
  if (appAuthCode.substr(6, 8) === localCode) {
    return true;
  } else {
    console.error('Mac验证错误, localCode: ' + localCode + ', appAuthCode: ' + appAuthCode + ', substr(6,8): ' + appAuthCode.substr(6, 8));
    return false;
  }
}

export function get34data() {
  return data34;

}

export function calculate34Data() {
  const accountAuthCode = SHM_QR.substr(34, 8);
  const macStr = getMacBySrc(SHM_QR.substr(34, 8));
  console.log('macStr', macStr)
  console.log(`000934${accountAuthCode}${macStr}`)
  data34 = hex2base64(`000934${accountAuthCode}${macStr}`);
  console.log('calculate34Data: ' + data34);
}

// 校验31数据是否正常
export function rewriteQrCode(data) {
  console.log('收到的31数据', data)
  let success = false;
  let result;
  if (data.length != 120) {
    console.log('data 数据长度异常，请检查');
    return false;
  }

  const dataMac = data.substr(112, 8);
  const chechMac = getMacBySrc(data.substr(6, 106));
  // 是否是磁悬浮站点
  const isNotMaglev = SHM_QR.substr(118, 14) == data.substr(94, 14);
  if (mockZhaji) {
    console.log('模拟闸机模式下不检查31报文的dataMac');
    success = true;
  } else {
    if (dataMac != chechMac) {
      console.log('dataMac 异常，请检查dataMac = ' + dataMac + ' checkMac = ' + chechMac);
      success = false;
    } else {
      // console.log('datamac 数据校验成功');
      // SHM_QR.replace(SHM_QR.substr(56, 58), data.substr(6, 58));

      success = true;
    }
  }

  if (isNotMaglev) {
    console.log('非磁浮进出站');
  } else if (success) {
    console.log('磁浮进出站');
    // success = false;
    // pointAction(SHMPoint.SHMPointStatus_30020, true);


  } else {
    success = fail;
    console.log('进出站异常');
  }
  // if (isIOS) {
  if (false) {
    console.log('IOS 直接回写32数据');
    const tmpSHMQR = changeStr(SHM_QR, 56, data.substr(32, 58));
    SHM_QR = tmpSHMQR;
    metroQrCodeRefresh(true, true);
    console.log('SHMQR', tmpSHMQR);
  } else {}
  const codeRefresh = parseInt(data.substr(data.length - 30, 4), 16)
  const refreshCounts = parseInt(SHM_QR.substr(114, 4), 16);
  console.log('31数据中的二维码刷新次数', codeRefresh)
  console.log('二维码刷新次数', refreshCounts)
  if (codeRefresh != refreshCounts) {
    console.log('二维码中刷新次数与31数据返回不一致')
    success = false;
  }

  if (success) {
    result =
      '000C3200' +
      data.substr(6, 2) +
      getCurrentSeconds().toString(16) +
      SHM_QR.substr(114, 2) +
      SHM_QR.substr(116, 2) +
      '000000';
  } else {
    result =
      '000C3201' +
      data.substr(6, 2) +
      getCurrentSeconds().toString(16) +
      SHM_QR.substr(114, 2) +
      SHM_QR.substr(116, 2) +
      '000000';
  }
  console.log('rewriteQrCode result = ' + result);
  return {
    uploadData: result,
    success: success,
    isNotMaglev,
  };
}
export function rewriteAndroid(data) {
  console.log('二维码', SHM_QR)
  console.log('31数据值', data)
  console.log('31数据中截取76位', data.substr(32, 58 + 22))
  // console.log('安卓回写32数据');
  const tmpSHMQR = changeStr(SHM_QR, 56, data.substr(32, 58 + 22));
  console.log('截取后的二维码值', tmpSHMQR)

  SHM_QR = tmpSHMQR;

  metroQrCodeRefresh(true, true);
  console.log('最终二维码', SHM_QR)
}
export function getFactorTime() {
  const low_time = parseInt(SHM_QR.substr(246, 2), 16);
  const high_time = parseInt(SHM_QR.substr(245, 1), 16);
  const tmp_time_high = high_time * 256;
  const tmp_time = low_time + tmp_time_high;
  console.log('分散因子有效时间为(分钟): ', tmp_time);
  return tmp_time;
}
// 检查日票是否合法
export async function checkTicketValid(tmpUnionKey) {
  if (!tmpUnionKey) {
    console.log('unionKey =', unionKey);
    tmpUnionKey = unionKey;
    // return false;
  }
  const saveKey = unionKey + 'SHMQR';
  // const localData = my.getStorageSync({ key: saveKey });
  let [localData] = await getLocalData({
    key: saveKey,
  });
  if (!localData) {
    return false;
  }
  const shmqr = localData && localData.qrCode;
  if (!shmqr || shmqr.length != 256) {
    console.log('unionKey 不合法');
    return false;
  }
  const current_seconds = getCurrentSeconds();
  // 获取日票激活时间
  const request_time = parseInt(shmqr.substr(138, 8), 16);
  // 获取日票有效时长
  const tmp_time = parseInt(shmqr.substr(164, 4), 16);
  if (request_time <= 0) {
    console.log('票启用时间不合法，直接返回');
    return false;
  }

  const period_time = request_time + tmp_time * 60 * 60;
  if (current_seconds > period_time) {
    return true;
  }
  console.log('当前日票已过期');

  return false;
}
export async function getisInStation(tmpUnionKey) {
  if (!tmpUnionKey) {
    tmpUnionKey = unionKey;
  }
  const saveKey = unionKey + 'SHMQR';
  // const localData = my.getStorageSync({ key: saveKey });

  let [localData] = await getLocalData({
    key: saveKey,
  });
  const shmqr = localData && localData.qrCode;
  if (!shmqr || shmqr.length != 256) {
    console.log('unionKey 不合法');
    return false;
  }

  const type = parseInt(shmqr.substr(56, 2), 10);
  if (type !== 0 && type < 80) {
    console.log('当前为进站状态');
    return true;
  }
  // 出站转态
  return false;
}

function pointAction(poingType, isNotify = false) {
  if (isNotify) {
    console.log('pointAction 回调' + poingType);
    qrCodePointFlow(poingType);
    // operatorDelegate.qrCodePointFlow(poingType);
  }
}

function getCurrentSeconds() {
  const timestamp = Date.parse(new Date());
  const currentSeconds = parseInt(timestamp) / 1000 + 8 * 60 * 60;
  return currentSeconds;
}

function getInitSHMQR() {
  let shmqr = '';
  for (let index = 0; index < K_QR_Length; index++) {
    shmqr = shmqr + '0';
    // console.log('index = ',index);
  }
  console.log('shmqr = ' + shmqr);
  return shmqr;
}
export function getInitSHMQR1() {
  let shmqr = '';
  for (let index = 0; index < K_QR_Length; index++) {
    shmqr = shmqr + '0';
    // console.log('index = ',index);
  }
  console.log('shmqr = ' + shmqr);
  return shmqr;
}

function getMacBySrc(src) {
  if(!SHM_PROCESS_KEY){
    return ''
  }
  
  var result = Pboc_des_mac(strToArray(SHM_PROCESS_KEY), strToArray(src)).join('');
  console.log('getMacBySrc: (' + src + '), result: ' + result + ', SHM_PROCESS_KEY: ' + SHM_PROCESS_KEY);

  return result;
}

function changeStr(str, index, changeStr) {
  return str.substr(0, index) + changeStr + str.substr(index + changeStr.length);
}

async function getDataFromLocal() {
  try {
    if (!unionKey) {
      return false;
    }
    // console.log('二维码初始值', SHM_QR) //
    const saveKey = unionKey + 'SHMQR';
    // console.log('获取二维码初始值', saveKey)
    let [localData] = await getLocalData({
      key: saveKey,
    });
    console.log('localData: ', localData);


    // load from api

    const openId = localStorage.getItem('openId');
    await store.dispatch('getQrcodeInfo', {
      openId
    });
   
    [localData] = await getLocalData({
      key: saveKey,
    });

    SHM_QR = localData.qrCode;
    SHM_PROCESS_KEY = localData.processKey;
    console.log('从缓存获取码值', SHM_QR)

  } catch (error) {
    console.error('error', error);
  }
}

export async function setDataToLocal() {
  const saveKey = unionKey + 'SHMQR';
  // console.log('存本地缓存', saveKey)
  // console.log('本地缓存的码值', SHM_QR)
  // 存本地缓存--生码
  ////  测试完成需要解开
  await setLocalData({
    key: saveKey,
    value: {
      qrCode: SHM_QR,
      processKey: SHM_PROCESS_KEY,
      appUserId: localStorage.getItem('appUserId')
    },
  });
}

export async function reSet() {
  SHM_QR = getInitSHMQR();
  return await setDataToLocal();
}

// set SHM_QR
export async function setShmQr(qrCode) {
  SHM_QR = qrCode;
  await setDataToLocal();
}

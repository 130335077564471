import {
  getIn
} from '../../utils';
import {
  Dialog,
  Toast
} from 'vant'
let isIOS = false;
export function initOSType(_os) {
  isIOS = _os;
}
/**
 * sdk使用promise封装
 */
export const addBankCard = () => {
  return new Promise(resolve => {
    upsdk.addBankCard({
      success: res => {
        // 绑卡成功
        resolve([res, undefined]);
      },
      fail: err => {
        // 绑卡失败或者用户取消
        resolve([undefined, err]);
      },
    });
  });
};

/**
 *   操作数据的错误码及含义
 *   code    msg
 *    00    操作成功
 *    01    参数非法
 *    02    应用唯一标识获取失败
 *    03    打开数据库失败
 *    04    保存数据失败
 *    05    删除数据失败
 *    06    删除的数据未找到
 *    07    未查找到数据
 *    08    其他错误
 *    10    数据超限
 *    11    key超过最大超限
 *    12    value超过每个记录最大限制
 */

/**
 * 存储数据
 */
/*
export const setLocalData = (evl, val) => {
  const key = evl.key;
  // 出参数据全部转为字符窜,取值时全部包一层后转回原本数据类型
  let value = JSON.stringify(evl.value);
  return new Promise(resolve => {
    upsdk.saveData({
      key,
      value,
      success: ({
        code = '',
        msg = ''
      }) => {
        if (code === '00') {
          resolve([true]);
        } else {
          resolve([false]);
        }
      },
      fail: ({
        code = '',
        msg = ''
      }) => {
        console.log('code---------', code, 'msg---------', msg, 'key:value', key, value);
      },
    });
  });
};
*/

// localstorage版本
export const setLocalData = (evl, val) => {
  const key = evl.key;
  // 出参数据全部转为字符窜,取值时全部包一层后转回原本数据类型
  let value = JSON.stringify(evl.value);

  localStorage.setItem(key, value)
  console.log('setLocalData[localStorage](' + key + ')')

  return true;
  //return new Promise(resolve => {

  //  resolve([true])

  //});
};

/**
 * 查询数据
 */
/*
export const getLocalData = evl => {
  if (!evl) {
    return console.log('getLocalData 入参为空');
  }
  const key = evl.key;
  return new Promise(resolve => {
    upsdk.queryData({
      key,
      success: ({
        code = '',
        msg = '',
        key,
        value
      }) => {
        // console.log('获取缓存入参' + JSON.stringify(evl))
        if (code === '00') {
          resolve([JSON.parse(value)]);
          // console.log(`缓存获取成功: { ${key}: ${value} }，${typeof JSON.parse(value)}`);
        } else {
          resolve([false]);
          console.log(`未获取到缓存信息，原因: ${code} ${msg}`);
        }
      },
      fail: ({
        code = '',
        msg = ''
      }) => {
        console.log('getLocalData fail', code, msg);
      },
    });
  });
};
*/

// localstorage版本
export const getLocalData = evl => {
  if (!evl) {
    return console.log('getLocalData 入参为空');
  }
  const key = evl.key;
  return new Promise(resolve => {

    var val = localStorage.getItem(key);
    if (val) {
      console.log('getLocalData[localStorage](' + key + ')')
      resolve([JSON.parse(val)])
    } else {
      console.log(`未获取到缓存信息[localStorage]，${key}`);
      resolve([false]);
    }

  });
};

/**
 * 删除数据
 */
/*
export const delLocalData = evl => {
  if (!evl) {
    return console.log('delLocalData 入参为空');
  }
  const key = JSON.stringify(evl);
  return new Promise(resolve => {
    upsdk.deleteData({
      key,
      success: ({
        code = '',
        msg = ''
      }) => {
        if (code === '00') {
          resolve([true]);
          console.log(`delLocalData success: { ${key}: ${params[keys]} }`);
        } else {
          resolve([false]);
          console.log(`delLocalData fail: { ${key}: ${params[keys]} }\n 失败原因: ${msg}`);
        }
      },
    });
  });
};
*/

// localstorage版本
export const delLocalData = evl => {
  if (!evl) {
    return console.log('delLocalData 入参为空');
  }
  const key = evl.key;

  console.log('delLocalData[localStorage](' + key + ')')
  localStorage.removeItem(key);

  return new Promise(resolve => {

    resolve([true])
  });
};

/**
 * 获取所有已存储数据的key值
 */
/*
export const getAllLocalData = () => {
  return new Promise(resolve => {
    upsdk.getAllKeys({
      success: ({
        code = '',
        msg = '',
        allKeys = []
      }) => {
        if (code === '00') {
          resolve([allKeys]);
          console.log(`getAllLocalData success`);
        } else {
          resolve([false]);
          console.log(`getAllLocalData fail  失败原因: ${msg}`);
        }
      },
    });
  });
};
*/

/**
 * 清除所有数据
 */
export const delAllLocalData = () => {
  return new Promise(resolve => {

    upsdk.deleteAllKeys({
      success: ({
        code = '',
        msg = ''
      }) => {
        if (code === '00') {
          resolve([true]);
          console.log(`delAllLocalData  success`);
        } else {
          resolve([false]);
          console.log(`delAllLocalDa \n 失败原因: ${msg}`);
        }
      },
    });
  });
};

/**
 * 判断安卓NFC
 */
export const isOpenNFC = () => {
  return new Promise((resolve, reject) => {
    upsdk.getNFCStatus({
      success: function ({
        status = ''
      }) {
        console.log('获取当前NFC是否打开。（仅支持Android）        status===', status);
        // 成功回调 data={"status":"0"}
        // 0:关闭；1:打开
        if (status === '0') {
          console.log('nfc关闭状态');
          resolve([true]);
          // 继续流程
        } else {
          // 提示用户关闭nfc
          console.log('提示用户关闭nfc');
          resolve([false]);
        }
      },
      fail: function (err) {
        console.log('判断nfc的SDK失败', err);
        resolve([false]);
      },
    });
  });
};


/**
 * 检查蓝牙状态
 * return true or false
 */
export const checkBlutoothStatus = () => {
  // function checkBlutoothStatus() {
  upsdk.getBluetoothStatus({
    success: function (data) {
      console.log(`判断蓝牙状态-success ${new Date().toLocaleTimeString()}  ${JSON.stringify(data)}`);
      const status = getIn(data, ['statusCode']) || getIn(data, ['state']) || '';
      if (status === 'PoweredOn') {
        return true;
      } else {
        return false;
      }
    },
    fail: function (code) {
      console.log('判断蓝牙状态-fail' + code + '  ' + new Date().toLocaleTimeString());
      resolve([false]);
    }
  })
}


export const getSystemInfo = () => {
  return new Promise(resolve => {
    //判断版本号
    upsdk.getSystemInfo({
      success: function (data) {
        console.log('手机信息', data);

        resolve(data);
      },
      fail: function (err) {
        console.error('getSystemInfo', err);
        resolve(false);
      },
    });

  });


};

/**
 * 判断蓝牙是否打开
 */
export const isOpenBlueTooth = () => {
  return new Promise(resolve => {
    upsdk.getBluetoothStatus({
      success: function (data) {
        console.log('判断蓝牙状态-success', data);
        const status = getIn(data, ['statusCode']) || getIn(data, ['state']) || '';
        console.log(status, '蓝牙状态')
        console.log(isIOS, 'isIOS')
        if (isIOS) {
          console.log('苹果手机')
          if (status === 'Unauthorized') {
            Toast.clear();

            Dialog.confirm({
              title: '提示',
              message: '云闪付蓝牙权限未开启，请在“设置”中找到“云闪付”开启蓝牙权限',
              showCancelButton: false,
              confirmButtonText: '知道了'
            }).then(() => {
              console.log('确认');
              // resolve([false]);
              upsdk.openAppSetting({
                success: function (data) {
                  // 插件执行成功 
                  console.log(data)
                },
                fail: function () {}
              });
              return
            })
            return

          }

        }

        if (status === 'PoweredOn') {
          // 打开蓝牙
          console.log('蓝牙已打开');
          resolve([true]);
        } else {
          // 提示用户手动打开蓝牙
          console.log('蓝牙关闭，提示：请打开蓝牙');
          // resolve([false]);
          // 多一层判断
          setTimeout(() => {
            // upsdk.getBluetoothAdapterState({
            //   success: function (data) {
            //     console.log('判断蓝牙状态-success', data);
            //     const status = getIn(data, ['available']) || '';
            //     console.log('available', status)
            //     if (status === 'yes') {
            //       console.log('蓝牙已打开');
            //       resolve([true]);
            //     } else {
            //       console.log('蓝牙关闭，提示：请打开蓝牙');
            //       resolve([false]);
            //     }
            //   },
            //   fail: function (code) {
            //     // 失败回调
            //     resolve([false]);
            //     console.log('判断蓝牙状态-fail', code);
            //   }
            // });

            upsdk.getBluetoothStatus({
              success: function (data) {
                console.log('判断蓝牙状态-success', data);
                const status = getIn(data, ['statusCode']) || getIn(data, ['state']) || '';
                if (status === 'PoweredOn') {
                  // 打开蓝牙
                  console.log('蓝牙已打开');
                  resolve([true]);
                } else {
                  // 提示用户手动打开蓝牙
                  console.log('蓝牙关闭，提示：请打开蓝牙');
                  resolve([false]);
                }
              },
              fail: function (code) {
                resolve([false]);
                console.log('判断蓝牙状态-fail', code);
              },
            });
          }, 2000);
        }
      },
      fail: function (code) {
        resolve([false]);
        console.log('判断蓝牙状态-fail', code);
      },
    });
  });
};

// 获取手机唯一标识
export const getDeviceId = () => {
  return new Promise(resolve => {
    upsdk.uniqueIdentifier({
      success: function (data) {
        // 成功回调 data={"uuid":"xxx"}
        const {
          uuid = ''
        } = data;
        console.log('获取手机唯一标识UUID' + uuid);
        if (uuid) {
          resolve([uuid]);
        } else {
          resolve([false]);
        }
      },
      fail: function (err) {
        resolve([false]);
        console.log('获取手机唯一标识UUID 失败', err);
      },
    });
  });
};

// 调整手机屏幕亮度
export const setScreenBrightness = brightness => {
  return new Promise(resolve => {
    if (!`${brightness}`) {
      resolve([false]);
    }
    upsdk.setScreenBrightness({
      brightness, // 屏幕亮度值，范围取值0-1。精确到小数点后一位
      success: function (data) {
        resolve([true]);
      },
      fail: function (msg) {
        resolve([false]);
      },
    });
  });
};

// 获取当前手机屏幕亮度
export const getScreenBrightness = () => {
  return new Promise(resolve => {
    upsdk.getScreenBrightness({
      success: function (data) {
        // console.log('获取当前手机屏幕亮度 成功', data);
        const {
          brightness
        } = data;
        resolve([brightness]);
      },
      fail: function (msg) {
        console.log('获取当前手机屏幕亮度 失败', msg);
        resolve([false]);
      },
    });
  });
};

/**
 * mutations
 */

export const mutations = {
  changeState: (state, payload) => {
    const keys = Object.keys(payload);
    keys.forEach(el => {
      state[el] = payload[el];
    });
  },
};

const CryptoJS = require('./crypto-js/crypto-js');
// let SHM_QR = '017eecefab8fd051000000000788513370f4e565cb004c5059ffffd200000000000000000000000000000000000000000000000000f49214a700010000000000000000005e2861895e25be89aabe0b40eda3ed83';
let SHM_QR = '';

let SHM_PROCESS_KEY = '';

const DEFAULT_CODE_PERIOD = 120;

const MAX_REFRESH_COUNTS = 60000;

const K_QR_Length = 256;

const qrLoaded = false;

const isIOS = true;

let pointStatus;

const ticketData = '';

const unionKey = 'qrcode';

let operatorDelegate; // operator.js 代理

export function installQrCode(info) {
  console.log('开始组装 info = ', info);
  if (info == null) {
    console.log('数据为空，直接返回');
    return false;
  }

  console.log(
    `bluetoothAddress = ${info.get('bluetoothAddress')} lenght = ${info.get('bluetoothAddress').length
    }`,
  );
  // 安卓 01 苹果 00
  const osType = '01';
  let index = 0;
  index = qrCodeItemPush(index, osType);

  const bluetoothAddress = info.get('bluetoothAddress');
  console.log('bluetoothAddress   = ', bluetoothAddress);
  console.log('bluetoothAddress length  = ', bluetoothAddress.length);
  // 手机号
  if (bluetoothAddress.length != 11) {
    console.log('手机蓝牙地址格式非法 请检查 = ', bluetoothAddress);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10000;
    return false;
  }
  // console.log('1')
  index = qrCodeItemPush(index, bluetoothAddress);

  // 用户Token
  const userToken = info.get('userToken');
  if (userToken.length != 20) {
    console.log('帐户token格式有误 请检查 = ', userToken);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10001;
    return false;
  }
  index = qrCodeItemPush(index, userToken);

  // console.log('2')

  // 账户认证码
  const certCode = info.get('certCode');
  if (certCode.length != 8) {
    console.log('账户认证码格式有误 请检查 = ', certCode);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10002;
    return false;
  }
  index = qrCodeItemPush(index, certCode);

  // 发卡方mac
  // console.log('3')

  const cardMac = info.get('cardMac');
  // if (cardMac.length != 8) {
  //   console.log('发卡方Mac格式有误 请检查 = ', cardMac);
  //   // _qrCodeStatus = SHMPoint.SHMPointStatus_10003;
  //   return false;
  // }
  index = qrCodeItemPush(index, cardMac);

  // 车站位置
  // console.log('4');
  const location = info.get('location');
  if (location.length != 4) {
    console.log('车站位置格式有误 请检查 = ', location);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10004;
    return false;
  }
  index = qrCodeItemPush(index, location);

  // 卡类型
  // console.log('5');
  const cardType = info.get('cardType');
  // if (cardType.length != 2) {
  //   console.log('卡类型格式有误 请检查 = ', cardType);
  //   // _qrCodeStatus = SHMPoint.SHMPointStatus_10005;
  //   return false;
  // }
  index = qrCodeItemPush(index, cardType);

  // 过程mac
  // console.log('6');
  index = 106;
  const dataMac = info.get('dataMac');
  if (dataMac.length != 8) {
    console.log('dataMac格式有误 请检查 = ', dataMac);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10015;
    return false;
  }
  if (SHM_QR.substr(106, 8) == '00000000') {
    index = qrCodeItemPush(index, dataMac);
  }

  // 磁浮限定
  // index = 118;
  // const cifu = '41FFFF';
  // const cifu = 'FFFFFF';
  // index = qrCodeItemPush(index, cifu);

  // 车票子类型
  // console.log('6');
  index = 136;
  const ticketType = info.get('ticketType');
  // if (ticketType.length != 2) {
  //   console.log('车票子类型格式有误 请检查 = ', ticketType);
  //   // _qrCodeStatus = SHMPoint.SHMPointStatus_10020;
  //   return false;
  // }
  index = qrCodeItemPush(index, ticketType);

  // 车票启用时间
  // index = 36;
  const activeTime = info.get('activeTime');
  // if (activeTime.length != 8) {
  //   console.log('车票启用时间有误 请检查 = ', activeTime);
  //   // _qrCodeStatus = SHMPoint.SHMPointStatus_10021;
  //   return false;
  // }
  index = qrCodeItemPush(index, activeTime);

  // 线路位图/车站位图1
  // index = 36;
  const stationBitmap1 = info.get('stationBitmap1');
  // if (stationBitmap1.length != 8) {
  //   console.log('线路位图/车站位图1 请检查 = ', stationBitmap1);
  //   // _qrCodeStatus = SHMPoint.SHMPointStatus_10022;
  //   return false;
  // }
  index = qrCodeItemPush(index, stationBitmap1);

  // 线路位图/车站位图1
  // index = 36;
  const stationBitmap2 = info.get('stationBitmap2');
  // if (stationBitmap2.length != 8) {
  //   console.log('线路位图/车站位2 请检查 = ', stationBitmap2);
  //   // _qrCodeStatus = SHMPoint.SHMPointStatus_10023;
  //   return false;
  // }
  index = qrCodeItemPush(index, stationBitmap2);

  // 范围标志
  const rangTag = info.get('rangTag');
  // if (rangTag.length != 2) {
  //   console.log('范围标志/范围标志 请检查 = ', rangTag);
  //   // _qrCodeStatus = SHMPoint.SHMPointStatus_10024;
  //   return false;
  // }
  index = qrCodeItemPush(index, rangTag);

  // 有效使用时间
  const effectiveHour = info.get('effectiveHour');
  // if (effectiveHour.length != 4) {
  //   console.log('有效使用时间 请检查 = ', effectiveHour);
  //   // _qrCodeStatus = SHMPoint.SHMPointStatus_10025;
  //   return false;
  // }
  index = qrCodeItemPush(index, effectiveHour);

  // 预留
  index = 216;
  const extendMac = info.get('extendMac');
  // if (extendMac.length != 8) {
  //   console.log('预留 请检查 = ', extendMac);
  //   // _qrCodeStatus = SHMPoint.SHMPointStatus_10026;
  //   return false;
  // }
  index = qrCodeItemPush(index, extendMac);

  index = 232;
  console.log('7');
  // 分散因子
  const factor = info.get('factor');
  if (factor.length != 16) {
    console.log('分散因子格式有误 请检查 = ', factor);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10006;
    return false;
  }
  index = qrCodeItemPush(index, factor);

  // processKey
  const processKey = info.get('processKey');
  console.log('8');
  if (processKey.length != 32) {
    console.log('processKey格式有误 请检查 = ', factor);
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10006;
    return false;
  }
  SHM_PROCESS_KEY = processKey;
  console.log('install SHMQR = ', SHM_QR);
  return metroQrCodeRefresh(true, false);
}

function metroQrCodeRefresh(isCountAdd, isRewrite) {
  const current_seconds = getCurrentSeconds();

  // 获取二维码请求时间
  const tmpData = SHM_QR.substr(232, 8);

  const request_time = parseInt(tmpData, 16);
  // TODO:不加时间的拼接
  // if (!request_time) {
  //   return SHM_QR;
  // }
  // TODO:以下为正常逻辑
  console.log('requestTime = ', request_time);
  // 计算分散因子的过期时间
  const tmp_time = getFactorTime();
  const period_time = request_time + tmp_time * 60;

  // #ifdef DEBUG
  // //    period_time = request_time + 60;
  //    request_time = current_seconds + 100;
  //
  // #endif

  if (current_seconds + 120 < request_time) {
    console.log('系统时间小于请求时间');
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10011;
    return false;
  }

  let tmp_period = period_time - DEFAULT_CODE_PERIOD;

  if (isRewrite) {
    console.log('回写时二维码有效期不减去刷新时间');
    tmp_period = period_time;
  }

  if (current_seconds >= tmp_period) {
    console.log('分散因子过期');
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10007;
    return false;
  }
  // #ifdef DEBUG
  //    isCountAdd  = NO;
  // #endif
  // 获取当前的二维码刷新次数
  let refreshCounts = parseInt(SHM_QR.substr(114, 4), 16);
  if (isCountAdd) {
    refreshCounts++;
  }
  if (refreshCounts >= MAX_REFRESH_COUNTS) {
    console.log('当前二维码刷新次数超出限制 请重新初始化 refreshCounts:', refreshCounts);
    // 生码失败埋点
    // _qrCodeStatus = SHMPoint.SHMPointStatus_10008;
    return false;
  }
  refreshCounts = refreshCounts.toString(16);
  // console.log(
  //   '开始本地刷新二维码时间戳 currentTime: %ld + requestTime: %ld + periodTime:%ld +  refreshCounts:%lu',
  //   current_seconds,
  //   request_time,
  //   period_time,
  //   refreshCounts,
  // );

  if (refreshCounts.length == 1) {
    refreshCounts = `000${refreshCounts}`;
  } else if (refreshCounts.length == 2) {
    refreshCounts = `00${refreshCounts}`;
  } else if (refreshCounts.length == 3) {
    refreshCounts = `0${refreshCounts}`;
  }

  if (refreshCounts.length == 4) {
    qrCodeItemPush(114, refreshCounts);
  } else {
    qrCodeItemPush(114, '0001');
  }

  const index = 224;
  // 计算当前二维码的有效期
  qrCodeItemPush(index, period_time.toString(16));
  // refreshappAuthCode(true);
  // return true;
  return refreshappAuthCode(true);
}

function refreshappAuthCode(isSaveLocal) {
  SHM_QR = SHM_QR.toLowerCase();
  const authCode = getMacBySrc(SHM_QR.substr(0, K_QR_Length - 8));
  qrCodeItemPush(K_QR_Length - 8, authCode);

  // console.log('authCode = ' + authCode);
  // console.log(`SHMQRCode  = ${SHM_QR}`);

  if (isSaveLocal) {
    setDataToLocal();
  }
  return SHM_QR;
  // 埋点生码成功状态
  // _qrCodeStatus = SHMPoint.SHMQRRefreshSucceed;
}

function qrCodeItemPush(index, data) {
  console.log(`index = ${index} data = `, data);
  // console.log('shmqr = '+SHM_QR);
  SHM_QR = changeStr(SHM_QR, index, data);
  // console.log('replace shmqr = '+SHM_QR);
  return index + data.length;
}

export function getFactorTime() {
  const low_time = parseInt(SHM_QR.substr(246, 2), 16);
  // console.log('SHM_QR.substr(157,3) = ',SHM_QR.substr(157,3));

  console.log('low_time = ', low_time);
  const high_time = parseInt(SHM_QR.substr(245, 1), 16);
  console.log('high_time  = ', high_time);

  const tmp_time_high = high_time * 256;
  const tmp_time = low_time + tmp_time_high;
  // console.log('分散因子有效时间为(分钟): ', tmp_time);
  return tmp_time;
}

function getCurrentSeconds() {
  console.log('getCurrentSeconds');
  const timestamp = Date.parse(new Date());
  const currentSeconds = parseInt(timestamp) / 1000 + 8 * 60 * 60;
  console.log(currentSeconds);
  return currentSeconds;
}

function getMacBySrc(src) {
  console.log(`getMacBySrc src = ${src}`);
  return Pboc_des_mac(strToArray(SHM_PROCESS_KEY), strToArray(src)).join('');
}

function changeStr(str, index, changeStr) {
  return str.substr(0, index) + changeStr + str.substr(index + changeStr.length);
}

function setDataToLocal() {
  const saveKey = `${unionKey}SHMQR`;
  console.log('saveKey=', saveKey);
}

export function Pboc_des_mac(keyByte, src) {
  let n, nlen, i;
  let sesdata = new Array(8);
  let dest = ['00', '00', '00', '00', '00', '00', '00', '00'];
  const last = ['80', '00', '00', '00', '00', '00', '00', '00'];

  const szKey = keyByte.slice(0, 8).concat();
  n = src.length;

  nlen = n + 8;
  if (n % 8 != 0) nlen -= n % 8;

  const data = new Array(nlen);
  data.splice(...[0, n].concat(src.slice(0, n).concat()));
  data.splice(...[n, 8].concat(last.concat()));

  for (i = 0; i < nlen; i += 8) {
    for (n = 0; n < 8; n++) {
      try {
        const tmp = xor(dest[n], data[i + n]);
        sesdata.splice(n, 1, String(tmp));
        // console.log('i = '+i+'n = ' +n);
      } catch (error) {
        console.log(`error  =  ${error}`);
      }
    }
    dest = encode(szKey, sesdata);
    if (dest == null) return null;
  }

  if (keyByte.length > 8) {
    szKey.splice(...[0, 8].concat(keyByte.slice(8, 16).concat()));
    sesdata = decode(szKey, dest);
    if (sesdata == null) return null;

    szKey.splice(...[0, 8].concat(keyByte.slice(0, 8).concat()));
    dest = encode(szKey, sesdata);
    if (dest == null) return null;
  }
  return dest.splice(0, 4).concat();
}
export function strToArray(str) {
  const len = str.length;
  console.log('strToArray-str.length = ', len);
  // if (len % 2 !== 0) {
  //   alert("Illegal Format ASCII Code!");
  //   return "";
  // }
  const resultStr = [];
  for (let i = 0; i < len; i += 2) {
    resultStr.push(str.substr(i, 2));
  }
  console.log('strToArray-resultStr = ', resultStr);
  return resultStr;
}
export let SHMPoint = {
  SHMQRNetSucceed: 90000, // 成功的 网络生成二维码
  SHMQRRefreshSucceed: 90001, // 成功的 刷新二维码
  SHMQRLocalSucceed: 90002, // 成功的 本地刷新二维码

  // 生码错误码
  SHMPointStatus_10000: 10000, // 手机蓝牙地址格式错误
  SHMPointStatus_10001: 10001, // 账户token格式错误
  SHMPointStatus_10002: 10002, // 账户认证码格式错误
  SHMPointStatus_10003: 10003, // 发卡方mac格式错误
  SHMPointStatus_10004: 10004, // 站格式错误
  SHMPointStatus_10005: 10005, // 卡类型错误
  SHMPointStatus_10006: 10006, // 分散因子格式错误
  SHMPointStatus_10007: 10007, // 散因子过期
  SHMPointStatus_10008: 10008, // 二维码刷新次数超出限制
  SHMPointStatus_10009: 10009, // 二维码数据格式错误
  SHMPointStatus_10010: 10010, // 网络获取二维码格式错误
  SHMPointStatus_10011: 10011, // 系统时间小于申请时间 eg:手机时间异常，请检查
  SHMPointStatus_10012: 10012, // 网络获取二维码失败 eg:超时或者服务器挂了
  SHMPointStatus_10013: 10013, // 进站失败
  SHMPointStatus_10014: 10014, // 出站失败
  SHMPointStatus_10015: 10015, // 过程Mac 格式错误
  SHMPointStatus_10016: 10016, // processkey 格式错误

  // 日票错误码
  SHMPointStatus_10020: 10020, // 日票 - 车票子类型异常
  SHMPointStatus_10021: 10021, // 日票 - 车票启用时间异常
  SHMPointStatus_10022: 10022, // 日票 - 车站位图1
  SHMPointStatus_10023: 10023, // 日票 - 车站位图2
  SHMPointStatus_10024: 10024, // 日票 - 范围标志
  SHMPointStatus_10025: 10025, // 日票 - 有效使用时间
  SHMPointStatus_10026: 10026, // 日票 - extendMac

  // 扫码错误码
  SHMPointStatus_20000: 20000, // 33 31数据包长度异常
  SHMPointStatus_20001: 20001, // 数据包mac串校验异常
  SHMPointStatus_20002: 20002, // 闸机回写二维码次数错误
  // 无法生码的错误码
  SHMPointStatus_20003: 20003, // 设备不支持BLE
  SHMPointStatus_20004: 20004, // 设备不支持BLE广播
  SHMPointStatus_20005: 20005, // 安卓设备系统低于5.0
  SHMPointStatus_20006: 20006, // 蓝牙打开超时
  SHMPointStatus_20007: 20007, // ble广播失败

  SHMPointStatus_20008: 20008, // 闸机超时未断开
  SHMPointStatus_20009: 20009, // 33数据返回失败
  SHMPointStatus_20010: 20010, // 31数据返回失败

  SHMPointStatus_20011: 20011, // APData保存失败
  SHMPointStatus_20012: 20012, // 钥匙串保存失败
  SHMPointStatus_20013: 20013, // 账户为空
  SHMPointStatus_20014: 20014, // 蓝牙service失败

  SHMPointStatus_20021: 20021, // 进站失败
  SHMPointStatus_20022: 20022, // 出站失败
  SHMPointStatus_20023: 20023, // 蓝牙打开异常
  SHMPointStatus_20024: 20024, // 用户看到的无法生码

  SHMPointStatus_30016: 30016, // 蓝牙订阅
  SHMPointStatus_30013: 30013, // 双向回写认证
  SHMPointStatus_30017: 30017, // 取消订阅
  SHMPointStatus_30018: 30018, // 广播启动成功
  SHMPointStatus_30019: 30019, // 33数据校验失败
  SHMPointStatus_30020: 30020, // 日票不支持磁浮进出站
};
// 加密
function encode(keyByte, src) {
  keyByte = keyByte.join('');
  src = src.join('');
  const keyHex = CryptoJS.enc.Hex.parse(keyByte);
  src = CryptoJS.enc.Hex.parse(src);
  const encrypted = CryptoJS.DES.encrypt(src, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.NoPadding,
  });
  const resultStr = encrypted.ciphertext.toString();
  return strToArray(resultStr);
}
// 解密
function decode(keyByte, src) {
  keyByte = keyByte.join('');
  src = src.join('');
  keyByte = CryptoJS.enc.Hex.parse(keyByte);
  src = CryptoJS.enc.Hex.parse(src);
  src = CryptoJS.enc.Base64.stringify(src);
  const plaintext = CryptoJS.DES.decrypt(src, keyByte, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.NoPadding,
  });
  const resultStr = plaintext.toString();
  return strToArray(resultStr);
}
const ua = navigator.userAgent;
console.log(ua, 'ua')

/**
 * 判断是否是安卓设备
 */
export function isAndroid() {
  return /Android/i.test(ua);
}

/**
 * 判断是否是苹果设备
 */
export function isIPadIOS() {
  return /iPad|iPod|iPhone|iOS/i.test(ua);
}
/**
 * 获取页面访问app环境
 */
export function getAppSource() {
  if (isAndroid()) {
    return {
      key: 'android',
      name: '安卓',
    };
  } else if (isIPadIOS()) {
    return {
      key: 'ios',
      name: '苹果',
    };
  }
  return {
    key: 'OTHER',
    name: '未知',
  };
}



// 16进制转base64
export function hex2base64(str) {
  const digits = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
  let base64_rep = '';
  let ascv = 0
  let bit_arr = 0;
  let bit_num = 0;

  for (let n = 0; n < str.length; ++n) {
    if (str[n] >= 'A' && str[n] <= 'Z') {
      ascv = str.charCodeAt(n) - 55;
    } else if (str[n] >= 'a' && str[n] <= 'z') {
      ascv = str.charCodeAt(n) - 87;
    } else {
      ascv = str.charCodeAt(n) - 48;
    }
    bit_arr = (bit_arr << 4) | ascv;
    bit_num += 4;
    if (bit_num >= 6) {
      bit_num -= 6;

      base64_rep += digits[bit_arr >>> bit_num];
      bit_arr &= ~(-1 << bit_num);
    }
  }

  if (bit_num > 0) {
    bit_arr <<= 6 - bit_num;
    base64_rep += digits[bit_arr];
  }
  let padding = base64_rep.length % 4;

  if (padding > 0) {
    for (let n = 0; n < 4 - padding; ++n) {
      base64_rep += '=';
    }
  }
  return base64_rep;
}


// base64 转16进制
export const stringToHex = str => {
  let val = ''
  for (var i = 0; i < str.length; i++) {
    //先转成unicode编码,再转成对应的16进制
    val += '' + str.charCodeAt(i).toString(16)
  }
  return val
}


export const base64ToHex = str => {
  if (!str) {
    return ''
  }
  const base64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/')
      .replace(/=/g, '');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  const buf2hex = buffer => { // buffer is an ArrayBuffer
    return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
  }
  return buf2hex(base64ToUint8Array(str))
}


export function getQueryString(key, paramString) {
  const paramStr = paramString || window.location.search;
  const regExp = new RegExp(`(^|\\?|&)${key}=([^&]*)(\\s|&|$)`);
  return regExp.test(paramStr) ? RegExp.$2 : '';
}

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import '@/assets/css/base.css';
import router from './router';
import App from './App.vue';
import axios from 'axios';
import Vant from 'vant';
import store from './store';
import VueMatomo from 'vue-matomo';
import { host, trackerScriptUrl, trackerFileName, matomoOpen, baseUrl } from '@/unified';
if (matomoOpen) {
  Vue.use(VueMatomo, {
    host: host, // 你自己的matomo地址
    trackerScriptUrl: trackerScriptUrl,
    siteId: 1, //siteId值，这个值页需要去matomo上去申请
    // 根据router自动注册，需要注意的是如果有路由传值值太长的话matomo会监听不到并报414，就不能使用此方法了。解决：app.vue监听路由
    router: router,
    // 是否需要在发送追踪信息之前请求许可，默认false
    requireConsent: false,
    enableLinkTracking: true,
    // 是否追踪初始页面，默认true
    trackInitialView: false,
    // 最终的追踪js文件名，matomo版本比较老，使用的是piwik，现在新版本的话此值为matomo
    trackerFileName: trackerFileName,
    debug: false,
  });
}

import 'vant/lib/index.css';
import './common/vue-cup-ui/lib/vue-cup-ui.css';
import VueCupUi from './common/vue-cup-ui';
import Head from '@/components/head';

import dayjs from 'dayjs';
Vue.prototype.$store = store;
Vue.prototype.$axios = axios;
Vue.prototype.$dayjs = dayjs;

Vue.component('Head', Head);
Vue.use(VueCupUi);
// Vue.use(axios);
Vue.use(Vant);
Vue.use(dayjs);

/*  ========== 捕获JS 错误日志，并上传到后台 ======  */
window._error_catch = (function(console){
    console.log('console:'+console);
  var categories = ['log', 'warn', 'error'];
  let logQueue = [];
  
  for(var i in categories){
    let category = categories[i];
    let oldHandler = console[category];
    console[category] = function(){
      logQueue.push({t: new Date(), c: category, a1: arguments[0], a2: arguments.length > 1 ? arguments[1] : null});
      
      while(logQueue.length > 200){
        logQueue.shift();
      }
      
      oldHandler.apply(null, arguments)
    }
  }
  
  var safeJsonStringify = function (obj){
    // 声明cache变量，便于匹配是否有循环引用的情况
    let cache = [];
    let str = JSON.stringify(obj, (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (cache.indexOf(value) !== -1) {
                // 移除
                return;
            }
            // 收集所有的值
            cache.push(value);
        }
        return value;
    });
    cache = null; // 清空变量，便于垃圾回收机制回收
    
    return str;
  }
  
  var printLogArg = function(arg){
    if(typeof arg == 'undefined'){
      return "undefined";
    }else if(arg == null){
      return "null";
    }else if(typeof arg == 'object'){
      return safeJsonStringify(arg);
    }else{
      return ""+arg;
    }
  }
  
  return {
    getLogQueue: function(){
      return logQueue;
    },
    reportLog: function(){
      try{
        var toUploadLog = logQueue;
        logQueue = [];
        
        var content = "";
        for(var i in toUploadLog){
          var log = toUploadLog[i];
          var line = log.t.toLocaleTimeString() + " " + log.c.toUpperCase()+": " + printLogArg(log.a1) + ", " + printLogArg(log.a2);
          content += line + "\n";
        }
        
        var openId = localStorage.getItem('openId');
        var xhr = new XMLHttpRequest();
        xhr.open("POST", baseUrl + '/dtb/error/submit', true);  //建立连接，要求同步响应
        xhr.setRequestHeader ('Content-Type', 'application/x-www-form-urlencoded');  //设置为表单方式提交
        
        var data = "content="+encodeURIComponent(content)+'&openId='+encodeURIComponent(openId ? openId : '');
        xhr.send(data);  //发送请求
      }catch(e){
        console.error('发送日志错误: ', e);
      }
    }
  }
})(console);

window.addEventListener('unhandledrejection', function (event) {
  // 网页重复发布后会报错，提示用户过期，重新加载页面
  console.error('页面错误[unhandledrejection]')
  console.error('页面错误[unhandledrejection]',event)
  if(event && event.reason && event.reason.message){
    console.error('event.message', event.reason.message)
    console.error('event.reason', event.reason.stack)
  }else{
    console.log(event)
  }
  event.preventDefault()
  window._error_catch.reportLog();
})
window.addEventListener('error', function (event) {
  console.log(arguments);
  console.error('页面错误[error]', true)
  console.error(event)
  
  window._error_catch.reportLog();
})


window.onerror = function (msg, url, row, col, error) {
  console.error('页面错误');
  console.error({
    msg,  url,  row, col, error
  })
  
  window._error_catch.reportLog();
}
/*  =========================== END ================================  */

// 清除缓存数据
localStorage.removeItem('adUrls'); // 广告
localStorage.removeItem('enableHCXAd');
localStorage.removeItem('openId');
localStorage.removeItem('followSt');
localStorage.removeItem('textBottomQrcode');

let cachedShmQr = localStorage.getItem('qrcodedaySHMQR');
let cachedDateHour = localStorage.getItem('qrcodedaySHMQRDateHour');
console.log('缓存码时间', cachedDateHour);
//alert('cachedDateHour: '+JSON.stringify(cachedDateHour));
/*
if (cachedShmQr && cachedDateHour) {

  cachedShmQr = JSON.parse(cachedShmQr);
  cachedDateHour = JSON.parse(cachedDateHour);

  var curDateHour = getDateHour();
  var needDelete = true;
  console.log('当前时间', curDateHour);
  //alert('curDateHour: '+JSON.stringify(curDateHour));
  if (curDateHour.date == cachedDateHour.date && ((cachedDateHour.hour > 3 && curDateHour.hour > 3) || (cachedDateHour.hour <= 3 && curDateHour.hour <= 3))) {
    // 缓存码的日期等于当前日期，且缓存码与当前时间小时数均小于4，或大于4时，不清除缓存码
    console.log('缓存码的日期等于当前日期，且缓存码与当前时间小时数均小于4，或大于4时，不清除缓存码')
    needDelete = false;
  } else if ((curDateHour.date != cachedDateHour.date && (curDateHour.time - cachedDateHour.time) < 24 * 3600 * 1000) && curDateHour.hour <= 3) {
    // 缓存码的日期是当前时间的前一天，而且当前时间日期小于3，不清除缓存码
    console.log('缓存码的日期是当前时间的前一天，而且当前时间日期小于3，不清除缓存码')
    needDelete = false;
  }

  //alert('needDelete: '+needDelete)
  if (needDelete) {
    localStorage.removeItem('qrcodedaySHMQR');
    localStorage.removeItem('qrcodedaySHMQRDateHour');
    localStorage.removeItem('logIn');
    localStorage.removeItem('logInStationCode');
    localStorage.removeItem('logInQRData');
    console.log('判断缓存码时间后，清除qrcodedaySHMQR与qrcodedaySHMQRDateHour');
  }

} else {
  localStorage.removeItem('qrcodedaySHMQR');
  localStorage.removeItem('qrcodedaySHMQRDateHour');
  localStorage.removeItem('logIn');
  localStorage.removeItem('logInStationCode');
  localStorage.removeItem('logInQRData');
  console.log('启动时清除qrcodedaySHMQR与qrcodedaySHMQRDateHour');
}
*/

router.beforeEach((to, from, next) => {
  const { meta = {} } = to;
  const { title = '上海地铁' } = meta;
  // eslint-disable-next-line no-undef
  upsdk.pluginReady(() => {
    // eslint-disable-next-line no-undef
    upsdk.setNavigationBarTitle({
      title,
    });
  });
  next();
});

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  router,
  store,
  components: {
    App,
  },
  template: '<App></App>',
});

import {
  getAppSource
} from '../utils';
import {
  SHMPoint
} from './SHMEnum';

const BLE = require('./SHMBLEManager');
const QR = require('./SHMQRCode');
const jssdk = require('../jssdk');
const platform = getAppSource();
let pageDelegate;

// 代理方法
export function delegate(page) {
  pageDelegate = page;
}

export function getQRCode() {
  QR.getQRCode();
}

function init() {
  if (platform.key === 'android') {
    // 安卓
    BLE.initOSType(false);
    QR.initOSType(false);
    jssdk.initOSType(false);
    BLE.initUUID(
      '000018F0-0000-1000-8000-00805F9B34FB',
      '00002AF0-0000-1000-8000-00805F9B34FB',
      '00002AF1-0000-1000-8000-00805F9B34FB',
    );
  } else {
    // 苹果
    BLE.initOSType(true);
    QR.initOSType(true);
    jssdk.initOSType(true);
    BLE.initUUID('18F0', '2AF0', '2AF1');
  }
  // BLE.initUUID(
  //   '000018F0-0000-1000-8000-00805F9B34FB',
  //   '00002AF0-0000-1000-8000-00805F9B34FB',
  //   '00002AF1-0000-1000-8000-00805F9B34FB',
  // );
}

export function start() {
  try {
    init();
    QR.delegate(pageDelegate);
    BLE.delegate(pageDelegate);
    BLE.start();
  } catch (e) {
    console.error(e);
  }
}

export async function reStart(flag) {
  console.log('reStart1', flag)
  console.log('生码+蓝牙 reStart方法执行');
  try {
    init();
    QR.delegate(pageDelegate);
    BLE.delegate(pageDelegate);
    return await BLE.reStart(flag);
  } catch (e) {
    console.error('reStart 错误 catch');
    console.error(e);
    console.error('reStart 错误 catch');
  }
}

export function reSet() {
  console.log('reSet');
  try {
    return QR.reSet();
  } catch (e) {
    console.error(e);
  }

  return false;
}

export function stop() {
  console.log('stop');
  try {
    BLE.stop();
  } catch (e) {
    console.error(e);
  }
}

// 刷新二维码
export function refreshQR(qrData, isHidden) {
  if (isHidden) {
    console.log('隐藏二维码');
    // return ;
  }else{
    console.log('refreshQR中被调用 展示二维码');
  }
  console.log('二维码刷新回调 data = ', qrData);
  console.log('refreshQR pageDelegate = ', pageDelegate);
  pageDelegate.refreshQRCode(qrData, isHidden);
}

// 二维码埋点数据流 数据流
export function qrCodePointFlow(ponintType) {
  console.log('qrCodePointFlow ponintType = ', ponintType);
  if (ponintType === SHMPoint.SHMPointStatus_30020) {
    maglevTrain();
  }
}

export function maglevTrain() {
  pageDelegate.maglevTrain();
}

// 回调进出站记录
export async function stationAction(isIn, isBom, gatedId) {
  console.log('operator stationAction');
  pageDelegate.stationAction(isIn, isBom, gatedId);
}

export function log(mlog) {
  console.log(mlog);
}

// 检查日票是否合法 true 合法
export function checkTicketValid(unionKey) {
  return QR.checkTicketValid(unionKey);
}

// 获取当前是否进站状态 true 进站状态
export function getInStation(unionKey) {
  return QR.getisInStation(unionKey);
}

import {
  saveRecordLog,
} from '../utils/api';
import {
  getAppSource
} from '@/common/utils';
import {
  setLocalData,
} from '@/common/jssdk';
/**
 * @desc 从一个对象通过操作序列来拿里面的值，做了基本防空措施
 * @param {object} state - 需要获取的数据源
 * @param {array} array - 操作路径
 * @param {any} initial - 默认值，当没有内容的时候
 * @example <caption>Example usage of getIn.</caption>
 * // testcase
 * {%common%}
 * // getIn
 * {%getIn%}
 * @returns {any} expected - 获取的值
 */
export function getIn(state, array, initial = null) {
  let obj = Object.assign({}, state);
  // array 数组判空
  if (!array || !Array.isArray(array)) {
    return null;
  }
  for (const i of array) {
    // when is undefined return init immediately
    if (typeof obj !== 'object' || obj === null) {
      return initial;
    }

    const prop = i;

    obj = obj[prop];
  }
  if (obj === undefined || obj === null) {
    return initial;
  }

  return obj;
}
/**
 * 防抖函数
 */
let timeout;
export function debounce(fn, wait = 500) {
  if (timeout !== null) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(fn, wait);
}
export function getQueryString(key, paramString) {
  const paramStr = paramString || window.location.search;
  const regExp = new RegExp(`(^|\\?|&)${key}=([^&]*)(\\s|&|$)`);
  return regExp.test(paramStr) ? RegExp.$2 : '';
}
export function list2Map() {
  var list = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var keyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  var map = {};
  var item = void 0;
  for (var i = 0, len = list.length; i < len; i++) {
    item = list[i];
    map[item[keyName]] = item;
  }
  return map;
}
export function handleArr(arr) {
  const res = Array.isArray(arr) ? arr : new Array();
  return res;
}
// 拜访管理-前端缓存 namespace：命名空间，visitPlanList=拜访计划  visitFeedbackList=拜访反馈
export const myLocalStorage = {
  set(namespace = 'visitPlanList', key, data, time) {
    const storage = JSON.parse(localStorage.getItem(namespace)) || {}
    storage[key] = {
      data,
      createTime: Date.now(),
      lifeTime: time || 1000 * 60 * 60 * 1 // 默认设置过期时间：1小时
    }
    localStorage.setItem(namespace, JSON.stringify(storage))
  },
  get(namespace = 'visitPlanList', key) {
    const storage = JSON.parse(localStorage.getItem(namespace)) || {}
    if (!storage[key]) return
    const {
      data,
      createTime,
      lifeTime
    } = storage[key]
    // 当前时间 - 存入时间 > 过期时间
    console.log(Date.now())
    console.log(createTime)
    console.log(lifeTime)
    console.log(Date.now() - createTime)
    if (Date.now() - createTime > lifeTime) {
      delete storage[key]
      localStorage.setItem(namespace, JSON.stringify(storage))
      return null
    } else {
      return data
    }
  },
  delete(namespace = 'visitPlanList', key) {
    const storage = JSON.parse(localStorage.getItem(namespace)) || {}
    if (!storage[key]) return
    delete storage[key]
    // localStorage.setItem(namespace, JSON.stringify(storage))
    localStorage.removeItem(namespace)
  }
}

export function getDateHour() {
  var d = new Date();
  var year = d.getFullYear();
  var month = d.getMonth() + 1;
  var date = d.getDate();
  var hour = d.getHours();

  return {
    date: year * 10000 + month * 100 + date,
    hour: hour,
    time: d.getTime()
  }
}

export function appendTripCache(trip) {
  var tripCache = localStorage.getItem('tripCache');
  if (tripCache) {
    tripCache = JSON.parse(tripCache);
  } else {
    tripCache = [];
  }

  tripCache.push(trip);
  console.log('保存' + trip.recCrtTs + '到trip缓存，当前缓存数量: ' + tripCache.length);
  localStorage.setItem('tripCache', JSON.stringify(tripCache));
}

function getAllTripCache() {
  var tripCache = localStorage.getItem('tripCache');
  console.log('tripCache',tripCache)
  if (tripCache) {
    tripCache = JSON.parse(tripCache);
  } else {
    tripCache = [];
  }

  return tripCache;
}

export function removeTripCache(_recCrtTs) {
  var tripCache = localStorage.getItem('tripCache');
  if (tripCache) {
    tripCache = JSON.parse(tripCache);
  } else {
    tripCache = [];
  }

  var newCache = [];
  for (var i in tripCache) {
    if (tripCache[i].recCrtTs != _recCrtTs) {
      newCache.push(tripCache[i]);
    }
  }

  console.log('删除' + _recCrtTs + '后保存到trip缓存，当前缓存数量: ' + newCache.length + ', 原缓存数量: ' + tripCache.length);
  localStorage.setItem('tripCache', JSON.stringify(newCache));
}

function removeTripCacheInner(_recCrtTs) {
  removeTripCache(_recCrtTs);
}

export async function flushTripCache() {
  var list = getAllTripCache();
  console.log('开始检查提交缓存数据: ' + list.length);

  for (var i in list) {
    await saveRecordLog(list[i])
      .then(res => {
        console.log(res)
        const logAdd = res.data;
        setLocalData({
          key: 'logIn',
          value: logAdd,
        });
        removeTripCacheInner(list[i].recCrtTs)
      }).catch(err => {
        console.log(err)
      });
  }
}
export async function jumpPage(item, router) {
  var platform = getAppSource();
  var url = new URL(item.href);
  var params = new URLSearchParams(url.searchParams);
  var paramObj = {};//Object.fromEntries(params);

  console.log('params: ', params);
  params.forEach(function(item, index){
    paramObj[index] = item;
    });
  console.log('paramObj: ', paramObj);
  
  /*if (!paramObj) {
    paramObj = {}
  }*/

  if (url.protocol == 'applet:') {
    console.log('使用applet跳转');
    var appId = '';
    if (platform.key === 'ios') {
      console.log('使用applet跳转',url.href.substring(url.href.indexOf('//') + 2));
      var str = url.href.substring(url.href.indexOf('//') + 2);
      var index = str.lastIndexOf('?');
      // appId = str.substring(0, index);
      if(index >= 0){
        appId = str.substring(0, index);
      }else{
        appId = str;
      }
    } else {
      appId = getCaption(url.pathname, '//');
    }
    console.log('使用applet跳转',appId);
    upsdk.openApplet({
      appId: appId, //必填，为16位小程序应用id
      param: paramObj.param ? paramObj.param : '', //可选，拼在小程序首页ur1后的查询参数
      isFinish: paramObj.isFinished == '1' ? '1' : '0', //可选，1表示关闭当前页面 非1不做处理，默认为0toLink:"ur1'，//可选，打开小程序时打开指定页，ur1不做编码，ur1要求域名在小程序安全域名范围内，仅支持云闪付9.0以上版本fail:function(code)[ //code=101表示appId为空,code=102表示找不到该应用
      toLink: paramObj.toLink ? paramObj.toLink : '',
      fail: function (code) {
        console.fail(code);
      },
    });
  } else if (url.protocol == 'rnpage:') {
    var dest = '';
    if (platform.key === 'ios') {
      var str = url.href.substring(url.href.indexOf('//') + 2);
      var index = str.lastIndexOf('?');
      dest = str.substring(0, index);
    } else {
      dest = getCaption(url.pathname, '//');
    }
    upsdk.openRNPage({
      dest: dest, // 必传，目标RN页面，示例中表示打开优惠详情页面rn名称（RN实现）
      isFinished: paramObj.isFinished == '1' ? '1' : '0', // 可选，默认为‘0’，为‘1’则关闭当前webview
      extraData: paramObj,
      success: function () {
        // 成功跳转
      },
      fail: function (msg) {
        // 失败回调
      },
    });
  } else if (url.protocol == 'inner:') {
      var str = url.href.substring(url.href.indexOf('//') + 2);
      var index = str.lastIndexOf('?');
      // appId = str.substring(0, index);
      var routeName = null;
      if(index >= 0){
        routeName = str.substring(0, index);
      }else{
        routeName = str;
      }
    
      console.log('routeTo: '+routeName, paramObj);
    
        router.push({
          name: routeName,
          query: paramObj
        });
  }
}

function getCaption(obj, text) {
  let index = obj.lastIndexOf(text) + text.length - 1;
  obj = obj.substring(index + 1, obj.length);
  return obj;
}

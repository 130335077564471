const CryptoJS = require('../crypto-js/crypto-js');

function tripleDesEncrypted() {
  console.log(' test ');

  try {
    const keyHex = CryptoJS.enc.Hex.parse('D7DF888F50A363F2');
    const src = CryptoJS.enc.Hex.parse('01136DCCA7620060');

    console.log('1111111');
    // console.log(CryptoJS);
    const encrypted = CryptoJS.DES.encrypt(src, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.NoPadding,
    });
    console.log(encrypted.toString());
    console.log(encrypted.ciphertext.toString());
  } catch (err) {
    console.log(`error = ${err}`);
  }
}

function getKey(keyByte) {
  console.log(keyByte);
  const szKey = new Array(24);

  if (keyByte.length % 8 != 0) return null;
  if (keyByte.length > 24) return null;

  if (keyByte.length == 24) return keyByte;

  if (keyByte.length == 8) {
    szKey.splice(...[0, 8].concat(keyByte));
    szKey.splice(...[8, 8].concat(keyByte));
    szKey.splice(...[16, 8].concat(keyByte));
    return szKey;
  }

  for (i = 0; i < 16; i++) {
    szKey.splice(i, 1, keyByte[i]);
  }
  for (i = 0; i < 8; i++) {
    szKey.splice(i + 16, 1, keyByte[i]);
  }
  return szkey;
}

function test() {
  a1 = [1, 2, 5, 6, 7];
  a2 = [3, 4];
  a1.splice(...[2, 1].concat(a2));
  console.log(a1);
}

export function Pboc_des_mac(keyByte, src) {
  let n, nlen, i;
  let sesdata = new Array(8);
  let dest = ['00', '00', '00', '00', '00', '00', '00', '00'];
  const last = ['80', '00', '00', '00', '00', '00', '00', '00'];

  const szKey = keyByte.slice(0, 8).concat();
  n = src.length;

  nlen = n + 8;
  if (n % 8 != 0) nlen -= n % 8;

  const data = new Array(nlen);
  data.splice(...[0, n].concat(src.slice(0, n).concat()));
  data.splice(...[n, 8].concat(last.concat()));
  for (i = 0; i < nlen; i += 8) {
    for (n = 0; n < 8; n++) {

      try {
        const tmp = xor(dest[n], data[i + n]);
        sesdata.splice(n, 1, String(tmp));
        // console.log('i = '+i+'n = ' +n);
      } catch (error) {
        console.log(`error  =  ${error}`);
      }
    }
    dest = encode(szKey, sesdata);
    if (dest == null) return null;
  }

  if (keyByte.length > 8) {
    szKey.splice(...[0, 8].concat(keyByte.slice(8, 16).concat()));
    sesdata = decode(szKey, dest);
    if (sesdata == null) return null;

    szKey.splice(...[0, 8].concat(keyByte.slice(0, 8).concat()));
    dest = encode(szKey, sesdata);
    if (dest == null) return null;
  }
  return dest.splice(0, 4).concat();
}

// 加密
function encode(keyByte, src) {
  keyByte = keyByte.join('');
  src = src.join('');
  const keyHex = CryptoJS.enc.Hex.parse(keyByte);
  src = CryptoJS.enc.Hex.parse(src);
  const encrypted = CryptoJS.DES.encrypt(src, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.NoPadding,
  });
  const resultStr = encrypted.ciphertext.toString();
  return strToArray(resultStr);
}

// 解密
function decode(keyByte, src) {
  keyByte = keyByte.join('');
  src = src.join('');
  keyByte = CryptoJS.enc.Hex.parse(keyByte);
  src = CryptoJS.enc.Hex.parse(src);
  src = CryptoJS.enc.Base64.stringify(src);
  const plaintext = CryptoJS.DES.decrypt(src, keyByte, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.NoPadding,
  });
  const resultStr = plaintext.toString();
  return strToArray(resultStr);
}

export function strToArray(str) {
  if (!str) {
    return '';
  }

  const len = str.length;
  if (len % 2 !== 0) {
    console.error('Illegal Format ASCII Code!: ' + str, new Error().stack);
    alert('Illegal Format ASCII Code!: ' + str);
    return '';
  }
  const resultStr = [];
  for (let i = 0; i < len; i += 2) {
    resultStr.push(str.substr(i, 2));
  }
  return resultStr;
}

function hex_to_bin(str) {
  // console.log('-------------hex_to_bin------------')
  if (str == 'd3') {
    // console.log(str);
  }
  const hex_array = [{
      key: 0,
      val: '0000'
    },
    {
      key: 1,
      val: '0001'
    },
    {
      key: 2,
      val: '0010'
    },
    {
      key: 3,
      val: '0011'
    },
    {
      key: 4,
      val: '0100'
    },
    {
      key: 5,
      val: '0101'
    },
    {
      key: 6,
      val: '0110'
    },
    {
      key: 7,
      val: '0111'
    },
    {
      key: 8,
      val: '1000'
    },
    {
      key: 9,
      val: '1001'
    },
    {
      key: 'a',
      val: '1010'
    },
    {
      key: 'b',
      val: '1011'
    },
    {
      key: 'c',
      val: '1100'
    },
    {
      key: 'd',
      val: '1101'
    },
    {
      key: 'e',
      val: '1110'
    },
    {
      key: 'f',
      val: '1111'
    }, ,
    {
      key: 'A',
      val: '1010'
    },
    {
      key: 'B',
      val: '1011'
    },
    {
      key: 'C',
      val: '1100'
    },
    {
      key: 'D',
      val: '1101'
    },
    {
      key: 'E',
      val: '1110'
    },
    {
      key: 'F',
      val: '1111'
    },
  ];

  let value = '';
  for (let i = 0; i < str.length; i++) {
    for (let j = 0; j < hex_array.length; j++) {
      if (str.charAt(i) == hex_array[j].key) {
        value = value.concat(hex_array[j].val);
        break;
      }
    }
  }
  // console.log(value)
  return value;
}

function bin_to_hex(str) {
  // console.log('----------bin_to_hex---------')
  const hex_array = [{
      key: 0,
      val: '0000'
    },
    {
      key: 1,
      val: '0001'
    },
    {
      key: 2,
      val: '0010'
    },
    {
      key: 3,
      val: '0011'
    },
    {
      key: 4,
      val: '0100'
    },
    {
      key: 5,
      val: '0101'
    },
    {
      key: 6,
      val: '0110'
    },
    {
      key: 7,
      val: '0111'
    },
    {
      key: 8,
      val: '1000'
    },
    {
      key: 9,
      val: '1001'
    },
    {
      key: 'a',
      val: '1010'
    },
    {
      key: 'b',
      val: '1011'
    },
    {
      key: 'c',
      val: '1100'
    },
    {
      key: 'd',
      val: '1101'
    },
    {
      key: 'e',
      val: '1110'
    },
    {
      key: 'f',
      val: '1111'
    },
    {
      key: 'A',
      val: '1010'
    },
    {
      key: 'B',
      val: '1011'
    },
    {
      key: 'C',
      val: '1100'
    },
    {
      key: 'D',
      val: '1101'
    },
    {
      key: 'E',
      val: '1110'
    },
    {
      key: 'F',
      val: '1111'
    },
  ];
  let value = '';
  const list = [];
  // console.log(str)
  if (str.length % 4 !== 0) {
    const a = '0000';
    const b = a.substring(0, 4 - (str.length % 4));
    str = b.concat(str);
  }
  // console.log(str)
  while (str.length > 4) {
    list.push(str.substring(0, 4));
    str = str.substring(4);
  }
  list.push(str);
  // console.log(list)
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < hex_array.length; j++) {
      if (list[i] == hex_array[j].val) {
        value = value.concat(hex_array[j].key);
        break;
      }
    }
  }
  return value;
}

function xor(a, b) {
  let A = hex_to_bin(a);
  let B = hex_to_bin(b);
  // console.log(a+"   a的二进制:"+A)
  // console.log(b+"   b的二进制:"+B)
  const o = '00000000000000000000000000000000000';
  if (A.toString().length > B.toString().length) {
    const c = A.toString().length - B.toString().length;
    B = o.substr(0, c).concat(B);
  } else if (A.toString().length < B.toString().length) {
    const c = B.toString().length - A.toString().length;
    A = o.substr(0, c).concat(A);
  }
  // console.log('B:'+B)
  // console.log('A:'+A)
  let d = '';
  for (let i = 0; i < A.toString().length; i++) {
    if (A.toString()[i] == B.toString()[i]) {
      const q = '0';
      d = d.concat(q);
    } else {
      const p = '1';
      d = d.concat(p);
    }
  }
  // console.log(bin_to_hex(d))
  return bin_to_hex(d);
}

// var a = encode('');
//
//   var b = encode(strToArray('D7DF888F50A363F2'),strToArray('01136DCCA7620060'));
//   var b = decode(strToArray('D7DF888F50A363F2'),strToArray('88CEE30BE42CCE3B'));

// console.log(b);

// var a = encrypt('mssage123', '123');
// var b = decrypt(a.value, a.key);

// console.log(a.value.length)

// test()
// strToArray('112233445566');

// tripleDesEncrypted();

let configure = {
  enable302935Ad: '1', // 默认值
  enable302936Ad: '1', // 默认值
  enableYsfIframeAd: '0',
  enableCardCenter: '0', // 启用卡券中心
};

export function resetConfig(_config){
  for(var i in _config){
    configure[i] = _config[i];
  }
  console.log('重置配置项', configure);
}

export function getConfig(key, defaultValue){
  if(configure[key] == null || configure[key] == undefined){
    console.log('返回配置项'+key+'，默认值: '+defaultValue);
    return defaultValue;
  }else{
    console.log('返回配置项'+key+'，配置值: '+configure[key]);
    return configure[key];
  }
}